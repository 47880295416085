<template>
  <div class="price-card">
    <div
      :class="['price-card-price', 'price-card-price-' + color]"
      :style="
        'background-image:url(' +
          require(`~/assets/images/pattern_transparent_${color}.png`) +
          ')'
      "
    >
      <Typography
        class="price-card-price-name"
        type="heading"
        typelevel="4"
        color="black"
        colorscale="100"
        sptype="heading"
        sptypelevel="5"
        >{{ name }}</Typography
      >
      <div class="price-card-price-numbers">
        <Typography
          type="text"
          typelevel="1"
          color="black"
          colorscale="100"
          sptype="text"
          sptypelevel="2"
          >1プロジェクト</Typography
        >
        <div class="price-card-price-text">
          <Typography
            type="heading"
            typelevel="2"
            color="black"
            colorscale="100"
            sptype="heading"
            sptypelevel="4"
            >¥</Typography
          >
          <Typography
            type="heading"
            typelevel="1"
            color="black"
            colorscale="100"
            sptype="heading"
            sptypelevel="3"
            >{{ priceNonTaxed }}</Typography
          >
        </div>
        <Typography
          type="text"
          typelevel="1"
          color="black"
          colorscale="060"
          sptype="text"
          sptypelevel="2"
        >
          {{ `(税込 ¥${priceTaxed})` }}
        </Typography>
      </div>
    </div>
    <div class="price-card-spec-list">
      <div class="price-card-spec">
        <img
          :src="require(`~/assets/images/icon-${color}-user.png`)"
          alt="参加者"
        />
        <Typography
          type="text"
          typelevel="1"
          color="black"
          colorscale="100"
          sptype="text"
          sptypelevel="2"
        >
          参加者
        </Typography>
        <Typography
          type="button"
          typelevel="1"
          color="black"
          colorscale="100"
          sptype="text"
          sptypelevel="2"
        >
          {{ `${guests}人まで` }}
        </Typography>
      </div>
      <div class="price-card-spec">
        <img
          :src="require(`~/assets/images/icon-${color}-quiz.png`)"
          alt="問題数"
        />
        <Typography
          type="text"
          typelevel="1"
          color="black"
          colorscale="100"
          sptype="text"
          sptypelevel="2"
        >
          問題数
        </Typography>
        <Typography
          type="button"
          typelevel="1"
          color="black"
          colorscale="100"
          sptype="text"
          sptypelevel="2"
        >
          制限なし
        </Typography>
      </div>
      <div>
        <div v-if="pricePerGuest !== undefined" class="price-card-spec">
          <img
            :src="require(`~/assets/images/icon-${color}-yen.png`)"
            alt="1人あたり"
          />
          <Typography
            type="text"
            typelevel="1"
            color="black"
            colorscale="100"
            sptype="text"
            sptypelevel="2"
          >
            1人あたり
          </Typography>
          <Typography
            type="button"
            typelevel="1"
            color="black"
            colorscale="100"
            sptype="text"
            sptypelevel="2"
          >
            {{ `${pricePerGuest}円以下` }}
          </Typography>
        </div>
        <Typography
          v-if="pricePerGuestAssumption !== undefined"
          type="text"
          typelevel="2"
          color="black"
          colorscale="060"
          class="price-card-spec-perguest-note"
          sptype="text"
          sptypelevel="3"
        >
          {{ `※参加者${pricePerGuestAssumption}人で利用した場合` }}
        </Typography>
      </div>
    </div>
  </div>
</template>

<script>
import Typography from '../../general/Typography.vue'

export default {
  components: { Typography },
  props: {
    name: {
      type: String,
      required: true
    },
    priceNonTaxed: {
      type: Number,
      required: true
    },
    priceTaxed: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    guests: {
      type: Number,
      required: true
    },
    pricePerGuest: {
      type: Number,
      default: undefined
    },
    pricePerGuestAssumption: {
      type: Number,
      default: undefined
    }
  }
}
</script>

<style scoped lang="scss">
.price-card {
  @include card_style();
  width: 336px;
  height: 396px;
  display: flex;
  flex-direction: column;
  @include for_tablet {
    height: auto;
    width: 100%;
  }
  @include for_mobile {
    height: auto;
    width: 100%;
  }
  &-price {
    width: 336px;
    height: 226px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: 300px;
    background-position: center;
    @include for_tablet {
      width: 100%;
      height: auto;
      padding: px_unit(2.5);
    }

    @include for_mobile {
      width: 100%;
      height: auto;
      padding: px_unit(2.5);
    }
    &-name {
      margin-bottom: px_unit(3);
      @include for_mobile {
        margin-bottom: px_unit(1.5);
      }
    }
    &-numbers {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: px_unit(1);
      @include for_mobile {
        gap: px_unit(0.5);
      }
    }
    &-text {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: px_unit(0.5);
    }
    &-green {
      background-color: $color_fill_green_010;
    }
    &-yellow {
      background-color: $color_fill_yellow_010;
    }
    &-black {
      background-color: $color_fill_black_005;
    }
  }
  &-spec {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: px_unit(0.5);

    img {
      width: 1.6rem;
      height: 1.6rem;
      @include for_mobile {
        width: 1.4rem;
        height: 1.4rem;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      gap: px_unit(2);
      padding: px_unit(3);
      flex-grow: 1;
      @include for_mobile {
        padding: px_unit(2.5);
        gap: px_unit(1.5);
      }
    }
    &-perguest-note {
      margin-top: px_unit(0.5);
    }
  }
}
</style>
