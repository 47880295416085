<template>
  <section :id="id" class="content-section">
    <div class="content-wrapper">
      <div class="content-title">
        <h1>
          <Typography
            type="heading"
            typelevel="3"
            color="black"
            colorscale="100"
            sptype="heading"
            sptypelevel="4"
          >
            {{ title }}
          </Typography>
        </h1>
        <Typography
          v-if="description !== undefined"
          type="subheading"
          typelevel="3"
          color="black"
          colorscale="060"
          sptype="text"
          sptypelevel="1"
          class="content-title-description"
        >
          {{ description }}
        </Typography>
      </div>
      <slot />
    </div>
  </section>
</template>

<script>
import Typography from './Typography.vue'

export default {
  components: { Typography },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: undefined
    },
    id: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style scoped lang="scss">
.content-section {
  padding-top: $size_section_desktop_padding_top;
  padding-bottom: $size_section_desktop_padding_bottom;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100vw;
  @include for_tablet {
    padding-top: $size_section_mobile_padding_top;
    padding-bottom: $size_section_mobile_padding_bottom;
  }
  @include for_mobile {
    padding-top: $size_section_mobile_padding_top;
    padding-bottom: $size_section_mobile_padding_bottom;
  }
  .content-wrapper {
    width: $size_breakpoint_desktop;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include for_tablet {
      width: 100vw;
      padding: 0 $size_section_mobile_padding_side;
    }
    @include for_mobile {
      width: 100vw;
      padding: 0 $size_section_mobile_padding_side;
    }
  }
  .content-title {
    margin-bottom: px_unit(5);
    word-break: keep-all;
    &-description {
      margin-top: px_unit(3);
    }
    @include for_tablet {
      margin-bottom: px_unit(4);
      &-description {
        margin-top: px_unit(2);
      }
    }
    @include for_mobile {
      margin-bottom: px_unit(4);
      &-description {
        margin-top: px_unit(2);
      }
    }
  }
}
</style>
