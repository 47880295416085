<template>
  <div>
    <div class="spinner">
      <Spinner />
    </div>
    <div>
      <p class="text">powered by</p>
      <div class="logo">
        <Logo color="black" />
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '~/components/atoms/Spinner.vue'
import Logo from '~/components/general/Logo.vue'

export default {
  components: {
    Spinner,
    Logo
  }
}
</script>

<style scoped lang="scss">
.spinner {
  margin-bottom: 24px;
  display: flex;
  justify-content: center;

  > svg {
    width: 64px;
    height: 64px;
  }
}

.text {
  text-align: center;
  font-size: 14px;
  color: $color_text_black_100;
}

.logo {
  display: flex;
  justify-content: center;

  > svg {
    width: 155px;
    height: 30px;
  }
}
</style>
