<template>
  <section
    id="demo-section"
    :style="
      'background-image:url(' +
        require(`~/assets/images/pattern_transparent_green.png`) +
        ')'
    "
  >
    <div class="content-wrapper">
      <h1>
        <Typography
          type="heading"
          typelevel="3"
          color="black"
          colorscale="100"
          class="demo-heading"
          sptype="heading"
          sptypelevel="4"
          >{{ `まずは\nサンプルクイズを\n見てみよう` }}</Typography
        >
      </h1>
      <div class="demo-phone">
        <img
          :src="require(`~/assets/images/phone_02.png`)"
          alt="スマートフォン"
          class="demo-phone-image"
        />
        <img
          :src="require(`~/assets/images/demo-url.png`)"
          alt="二次元コード"
          class="demo-phone-qa"
        />
        <a :href="demoUrl" class="demo-phone-button" @click="handleStartDemo">
          <Button type="primary" color="dark-green">サンプルを確認する</Button>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import Typography from '../../general/Typography.vue'
import Button from '../../general/Button.vue'

export default {
  components: { Typography, Button },
  props: {
    demoUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    handleStartDemo() {
      this.$emit('onStartDemo')
    }
  }
}
</script>

<style scoped lang="scss">
#demo-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: $size_inserted_section_desktop_height;
  background-size: 580px;
  background-position: center;
  @include for_mobile {
    height: auto;
    background-size: 400px;
    padding-top: $size_section_mobile_padding_top;
    padding-bottom: 0;
  }

  .content-wrapper {
    max-width: $size_breakpoint_desktop;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include for_mobile {
      width: 100vw;
      flex-direction: column;
      align-items: flex-start;
      gap: px_unit(1);
    }
  }
  .demo-heading {
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: left;
    padding: 0 0 0 $size_section_mobile_padding_side;
  }
  .demo-phone {
    $demo-phone-width: 400px;
    position: relative;
    width: $demo-phone-width;
    height: $size_inserted_section_desktop_height;
    overflow: hidden;

    @include for_mobile {
      width: 100vw;
      height: 218px;
      display: flex;
      justify-content: center;
    }
    &-qa {
      width: 130px;
      height: 130px;
      position: absolute;
      bottom: 0px;
      left: 0px;
      z-index: 2;
      margin: 0 ($demo-phone-width - 130px)/2 170px;
      @include for_mobile {
        display: none;
      }
    }
    &-image {
      width: 336px;
      height: 687.63px;
      z-index: 1;
      position: relative;
      top: 40px;
      @include card_style();
      border: none;
      border-radius: 47px;
      background-color: none;
      @include for_mobile {
        top: 0px;
        right: -50px;
        width: 248px;
        height: auto;
        border-radius: 0px;
        position: absolute;
      }
    }
    &-button {
      z-index: 3;
      position: absolute;
      bottom: 0px;
      display: block;
      margin: 0 ($demo-phone-width - $size_button_desktop_width)/2 px_unit(10);
      @include for_mobile {
        margin: 0 0 px_unit(10);
      }
    }
  }
}
</style>
