<template>
  <div v-if="device2 === undefined" :class="['use-case-panel', color]">
    <Typography
      type="heading"
      typelevel="5"
      :color="color"
      colorscale="100"
      sptype="button"
      sptypelevel="1"
    >
      {{ title }}
    </Typography>
    <img
      :class="['screen-card', device]"
      :src="require(`~/assets/images/${image}.png`)"
      :alt="action"
    />
    <Typography
      type="text"
      typelevel="1"
      color="black"
      colorscale="060"
      sptype="text"
      sptypelevel="2"
    >
      {{ action }}
    </Typography>
  </div>
  <div v-else :class="['use-case-panel', 'use-case-panel-double', color]">
    <Typography
      type="heading"
      typelevel="5"
      :color="color"
      colorscale="100"
      sptype="button"
      sptypelevel="1"
    >
      {{ title }}
    </Typography>
    <div class="screen-card-list">
      <div class="screen-card-list-double-content">
        <Typography
          type="heading"
          typelevel="5"
          :color="color"
          colorscale="100"
          class="use-case-panel-double-invisible-title"
          sptype="button"
          sptypelevel="1"
        >
          {{ title }}
        </Typography>
        <img
          :class="['screen-card', device]"
          :src="require(`~/assets/images/${image}.png`)"
          :alt="action"
        />
        <Typography
          type="text"
          typelevel="1"
          color="black"
          colorscale="060"
          sptype="text"
          sptypelevel="2"
        >
          {{ action }}
        </Typography>
      </div>
      <div class="screen-card-list-double-content">
        <Typography
          type="heading"
          typelevel="5"
          :color="color"
          colorscale="100"
          class="use-case-panel-double-invisible-title"
          sptype="button"
          sptypelevel="1"
        >
          {{ title }}
        </Typography>
        <img
          :class="['screen-card', device2]"
          :src="require(`~/assets/images/${image2}.png`)"
          :alt="action2"
        />
        <Typography
          type="text"
          typelevel="1"
          color="black"
          colorscale="060"
          sptype="text"
          sptypelevel="2"
        >
          {{ action2 }}
        </Typography>
      </div>
    </div>
  </div>
</template>

<script>
import Typography from '../../general/Typography.vue'

export default {
  components: { Typography },
  props: {
    color: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    device: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    device2: {
      type: String,
      default: undefined
    },
    image2: {
      type: String,
      default: undefined
    },
    action2: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style scoped lang="scss">
.use-case-panel {
  border-radius: $size_radius;
  padding: px_unit(4);
  width: 336px;
  height: 412px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @include for_tablet {
    padding: px_unit(3);
    width: 100%;
    height: auto;
    gap: px_unit(2);
  }

  @include for_mobile {
    padding: px_unit(3);
    width: 100%;
    height: auto;
    gap: px_unit(2);
  }
  &-double {
    width: 688px;
    height: 412px;
    flex: 2;
    @include for_tablet {
      width: 100%;
      height: auto;
    }

    @include for_mobile {
      width: 100%;
      height: auto;
    }
    &-invisible-title {
      opacity: 0;
      @include for_mobile {
        display: none;
      }
    }
    .screen-card-list {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      @include for_mobile {
        flex-direction: column;
        gap: px_unit(2.5);
      }
      &-double-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 336px - px_unit(4) - px_unit(4);
        height: 412px - px_unit(4) - px_unit(4);
        transform: translateY(-22px);

        @include for_tablet {
          justify-content: space-between;
          gap: px_unit(2);
          width: 100%;
          height: auto;
          transform: translateY(0px);
        }
        @include for_mobile {
          justify-content: flex-start;
          gap: px_unit(2);
          width: 100%;
          height: auto;
          transform: translateY(0px);
        }
      }
    }
  }
  &.green {
    background-color: $color_fill_green_010;
  }
  &.blue {
    background-color: $color_fill_blue_010;
  }

  .screen-card {
    @include card_style();
    border-radius: 2px;
    &.desktop {
      width: 250px;
      height: 190px;
      @include for_mobile {
        height: 95px;
        width: 125px;
      }
    }
    &.mobile {
      width: 117px;
      height: 252px;
      @include for_mobile {
        width: 65px;
        height: 140px;
      }
    }

    &.controller {
      width: 250px;
      height: 62.5px;
      @include for_mobile {
        height: 31.25px;
        width: 125px;
      }
    }
  }
}
</style>
