<template>
  <ContentSection
    id="use-case-section"
    title="ご利用方法"
    description="ご利用シーンや会場にあわせて 活用方法をお選びいただけます"
  >
    <div class="use-case-list">
      <div class="use-case use-case-01">
        <div class="use-case-title">
          <Typography
            type="heading"
            typelevel="5"
            color="green"
            colorscale="100"
            class="use-case-title-case"
            sptype="button"
            sptypelevel="1"
          >
            CASE 01
          </Typography>
          <Typography
            type="heading"
            typelevel="4"
            color="black"
            colorscale="100"
            class="use-case-title-heading"
            sptype="heading"
            sptypelevel="5"
          >
            パソコンをプロジェクターに繋いで
          </Typography>
        </div>
        <div class="use-case-panel-list">
          <UseCasePanel
            title="あなたのパソコン"
            color="green"
            device="desktop"
            image="desktop_01"
            action="クイズの表示"
            device2="controller"
            image2="desktop_04"
            action2="スライドの操作"
          />
          <UseCasePanel
            title="参加者のスマートフォン"
            color="blue"
            device="mobile"
            image="mobile_02"
            action="クイズに参加"
          />
        </div>
      </div>
      <div class="use-case use-case-01">
        <div class="use-case-title">
          <Typography
            type="heading"
            typelevel="5"
            color="green"
            colorscale="100"
            class="use-case-title-case"
            sptype="button"
            sptypelevel="1"
          >
            CASE 02
          </Typography>
          <Typography
            type="heading"
            typelevel="4"
            color="black"
            colorscale="100"
            class="use-case-title-heading"
            sptype="heading"
            sptypelevel="5"
          >
            スライドの操作をスマートフォンで
          </Typography>
        </div>
        <div class="use-case-panel-list">
          <UseCasePanel
            title="あなたのパソコン"
            color="green"
            device="desktop"
            image="desktop_01"
            action="クイズの表示"
          />
          <UseCasePanel
            title="あなたのスマートフォン"
            color="green"
            device="mobile"
            image="mobile_06"
            action="スライドの操作"
          />
          <UseCasePanel
            title="参加者のスマートフォン"
            color="blue"
            device="mobile"
            image="mobile_02"
            action="クイズに参加"
          />
        </div>
      </div>
      <div class="use-case use-case-01">
        <div class="use-case-title">
          <Typography
            type="heading"
            typelevel="5"
            color="green"
            colorscale="100"
            class="use-case-title-case"
            sptype="button"
            sptypelevel="1"
          >
            CASE 03
          </Typography>
          <Typography
            type="heading"
            typelevel="4"
            color="black"
            colorscale="100"
            class="use-case-title-heading"
            sptype="heading"
            sptypelevel="5"
          >
            プロジェクターを使わずスマートフォン1つで
          </Typography>
        </div>
        <div class="use-case-panel-list use-case-panel-list-last">
          <UseCasePanel
            title="あなたのスマートフォン"
            color="green"
            device="mobile"
            image="mobile_07"
            action="クイズの表示/スライドの操作"
          />
          <UseCasePanel
            title="参加者のスマートフォン"
            color="blue"
            device="mobile"
            image="mobile_02"
            action="クイズに参加"
          />
        </div>
      </div>
    </div>
  </ContentSection>
</template>

<script>
import ContentSection from '../../general/ContentSection.vue'
import Typography from '../../general/Typography.vue'
import UseCasePanel from './UseCasePanel.vue'

export default {
  components: { ContentSection, Typography, UseCasePanel },
  props: {}
}
</script>

<style scoped lang="scss">
#use-case-section {
  background-color: $color_fill_white_100;
  .use-case-title {
    text-align: left;
    &-case {
      margin-bottom: px_unit(1);
      @include for_mobile {
        margin-bottom: px_unit(1);
      }
    }
    &-heading {
      margin-bottom: px_unit(4);
      @include for_mobile {
        margin-bottom: px_unit(2);
      }
    }
  }
  .use-case-list {
    display: flex;
    flex-direction: column;
    gap: px_unit(6);
    @include for_tablet {
      width: 100vw;
      padding: 0 $size_section_mobile_padding_side;
    }

    @include for_mobile {
      width: 100vw;
      padding: 0 $size_section_mobile_padding_side;
      gap: px_unit(4);
    }
  }
  .use-case-panel-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: px_unit(2);
    @include for_tablet {
      flex-direction: column;
    }
    @include for_mobile {
      flex-direction: column;
      gap: px_unit(1.5);
    }
    &-last {
      justify-content: end;
      @include for_mobile {
        justify-content: center;
      }
    }
  }
}
</style>
