<template>
  <header v-if="scrollY > 100" class="stick-header">
    <div class="stick-header-wrapper">
      <Logo class="header-logo" color="black" />
      <div class="header-actions header-actions-desktop">
        <a :href="loginUrl" @click="handleLogin">
          <Button
            class="header-login-button"
            type="secondary"
            color="light-green"
            >ログイン</Button
          >
        </a>
        <a :href="signupUrl" @click="handleSignup">
          <Button class="header-signup-button" type="secondary" color="dark-red"
            >まずは無料でお試し</Button
          >
        </a>
      </div>
      <div class="header-actions header-actions-mobile">
        <a :href="loginUrl" @click="handleLogin">
          <TextLink color="green">ログイン</TextLink>
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import Logo from '../../general/Logo.vue'
import Button from '../../general/Button.vue'
import TextLink from '../../general/TextLink.vue'

export default {
  components: { Logo, Button, TextLink },
  props: {
    loginUrl: {
      type: String,
      required: true
    },
    signupUrl: {
      type: String,
      required: true
    },
    scrollY: {
      type: Number,
      default: 0
    }
  },
  methods: {
    handleSignup() {
      this.$emit('onSignup')
    },
    handleLogin() {
      this.$emit('onLogin')
    }
  }
}
</script>

<style scoped lang="scss">
.stick-header {
  position: fixed;
  width: 100vw;
  height: $size_header_desktop_height;
  background-color: $color_fill_white_100;
  top: 0px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  @include card_style;
  @include for_mobile {
    height: $size_header_mobile_height;
  }
  &-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: $size_breakpoint_desktop_outer;
    width: 100vw;

    @include for_tablet {
      width: 100vw;
      padding: 0 $size_section_mobile_padding_side;
    }

    @include for_mobile {
      width: 100vw;
      padding: 0 $size_section_mobile_padding_side;
    }
  }
  .header {
    &-logo {
      height: px_unit(3);
      @include for_mobile {
        height: px_unit(2.5);
      }
    }
    &-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: px_unit(3);
      &-desktop {
        @include for_mobile {
          display: none;
        }
      }
      &-mobile {
        display: none;
        @include for_mobile {
          display: flex;
        }
      }
    }
    &-login-button {
      width: $size_smallbutton_desktop_width;
      height: $size_smallbutton_desktop_height;
    }
    &-signup-button {
      width: $size_button_desktop_width;
      height: $size_smallbutton_desktop_height;
    }
  }
}
</style>
