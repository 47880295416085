<template>
  <div class="voice-card">
    <Typography
      type="text"
      typelevel="1"
      color="black"
      colorscale="100"
      sptype="text"
      sptypelevel="2"
      >{{ voice }}</Typography
    >
    <Typography
      type="text"
      typelevel="2"
      color="black"
      colorscale="060"
      class="voice-card-author"
      sptype="text"
      sptypelevel="3"
      >{{ author }}</Typography
    >
  </div>
</template>

<script>
import Typography from '../../general/Typography.vue'

export default {
  components: { Typography },
  props: {
    voice: {
      type: String,
      required: true
    },
    author: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.voice-card {
  padding: 0 px_unit(4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: px_unit(64);
  height: px_unit(31);
  @include card_style();
  @include for_tablet {
    width: 100%;
    height: auto;
    padding: px_unit(2.5);
  }
  @include for_mobile {
    width: 100%;
    height: auto;
    padding: px_unit(2.5);
  }
  &-author {
    margin-top: px_unit(2);
    text-align: right;
  }
}
</style>
