<template>
  <div class="faq-row">
    <Typography
      class="faq-row-question"
      type="heading"
      typelevel="5"
      color="black"
      colorscale="100"
      sptype="button"
      sptypelevel="1"
    >
      {{ question }}
    </Typography>
    <Typography
      class="faq-row-answer"
      type="text"
      typelevel="1"
      color="black"
      colorscale="060"
      sptype="text"
      sptypelevel="2"
    >
      {{ answer }}
    </Typography>
  </div>
</template>

<script>
import Typography from '../../general/Typography.vue'

export default {
  components: { Typography },
  props: {
    question: {
      type: String,
      required: true
    },
    answer: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.faq-row {
  width: 688px;
  display: flex;
  flex-direction: column;
  gap: px_unit(3);
  padding: px_unit(5) 0;
  text-align: left;
  @include for_mobile {
    width: 100%;
    gap: px_unit(2);
    padding: px_unit(4) 0;
    margin: 0 $size_section_mobile_padding_side;
  }
}
</style>
