import { render, staticRenderFns } from "./MainFeatureSection.vue?vue&type=template&id=6fdd7fc1&scoped=true&"
import script from "./MainFeatureSection.vue?vue&type=script&lang=js&"
export * from "./MainFeatureSection.vue?vue&type=script&lang=js&"
import style0 from "./MainFeatureSection.vue?vue&type=style&index=0&id=6fdd7fc1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fdd7fc1",
  null
  
)

export default component.exports