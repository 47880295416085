<template>
  <br class="desktop-break" />
</template>

<script>
export default {
  components: {},
  props: {}
}
</script>

<style scoped lang="scss">
.desktop-break {
  display: block;
  @include for_mobile {
    display: none;
  }
}
</style>
