<template>
  <section
    id="voices-section"
    :style="
      'background-image:url(' +
        require(`~/assets/images/background_voices.png`) +
        ')'
    "
  >
    <h1>
      <Typography
        type="heading"
        typelevel="3"
        color="white"
        colorscale="100"
        class="content-section-title"
        sptype="heading"
        sptypelevel="4"
      >
        お客様の声
      </Typography>
    </h1>
    <div class="voice-card-list">
      <VoiceCard
        voice="結婚式の準備はなにかと忙しいので、簡単に余興のゲームが出来てとても助かりました！参列者からも、スマホで簡単に参加できて分かりやすかったとの声をいただきました！"
        author="20代 女性"
      />
      <VoiceCard
        voice="テンプレートから簡単にクイズを作れました。開催するのも殆ど手間いらずで、会場の皆様がスマホで簡単に参加できたので、全員でクイズを楽しめて盛り上がりました！"
        author="20代 男性"
      />
      <VoiceCard
        voice="プロジェクターのないレストランで使ったのですが、クイズにスマホから参加できてよかったです。忘年会などでも盛り上がりそうなので、ぜひまた使いたいと思います。"
        author="30代 男性"
      />
    </div>
  </section>
</template>

<script>
import Typography from '../../general/Typography.vue'
import VoiceCard from './VoiceCard.vue'

export default {
  components: { Typography, VoiceCard },
  props: {}
}
</script>

<style scoped lang="scss">
#voices-section {
  background-color: $color_fill_black_005;
  padding-top: $size_section_desktop_padding_top;
  padding-bottom: $size_section_desktop_padding_bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: center 0;
  @include for_mobile {
    padding-top: $size_section_mobile_padding_top;
    padding-bottom: $size_section_mobile_padding_bottom;
  }
  .content-section-title {
    margin-bottom: px_unit(5);
    @include for_mobile {
      margin-bottom: px_unit(4);
    }
  }
  .voice-card-list {
    display: flex;
    justify-content: center;
    flex-direction: row;
    min-width: $size_breakpoint_desktop;
    gap: px_unit(2);
    @include for_tablet {
      width: 100vw;
      min-width: 100%;
      flex-direction: column;
      padding: 0 $size_section_mobile_padding_side;
    }
    @include for_mobile {
      flex-direction: column;
      gap: px_unit(1.5);
      padding: 0 $size_section_mobile_padding_side;
    }
  }
}
</style>
