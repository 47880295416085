<template>
  <ContentSection
    id="various-feature-section"
    title="豊富な機能"
    description="Ennoshitaはフリープランでも たくさんの機能をご利用いただけます"
  >
    <div class="feature-card-list">
      <FeatureCard
        class="feature-card"
        image="01"
        :description="`簡単\nマルバツクイズ`"
      />
      <FeatureCard
        class="feature-card"
        image="02"
        :description="`画像も使える\n４択クイズ`"
      />
      <FeatureCard
        class="feature-card"
        image="03"
        :description="`その場で答えが\n決まる実演クイズ`"
      />
      <FeatureCard
        class="feature-card"
        image="04"
        :description="`問題作成は\n何問でもOK`"
      />
      <FeatureCard
        class="feature-card"
        image="05"
        :description="`アプリなしで\n誰でも参加可能`"
      />
      <FeatureCard
        class="feature-card"
        image="06"
        :description="`スマホでもPCでも\nクイズが作れる`"
      />
      <FeatureCard
        class="feature-card"
        image="07"
        :description="`スマホでも\nスライド操作可能`"
      />
      <FeatureCard
        class="feature-card"
        image="08"
        :description="`ランキングを\n自動で集計`"
      />
    </div>
  </ContentSection>
</template>

<script>
import ContentSection from '../../general/ContentSection.vue'
// import MobileBreak from '../../general/MobileBreak.vue'
import FeatureCard from './FeatureCard.vue'

export default {
  components: { ContentSection, FeatureCard },
  props: {}
}
</script>

<style scoped lang="scss">
#various-feature-section {
  background-color: $color_fill_black_005;
  .feature-card-list {
    display: grid;
    justify-content: center;
    gap: px_unit(2);
    grid-template-columns: repeat(auto-fill, minmax(px_unit(31), 1fr));
    max-width: $size_breakpoint_desktop;
    width: 100vw;
    @include for_tablet {
      padding: 0 $size_section_mobile_padding_side;
    }
    @include for_mobile {
      gap: px_unit(1.5);
      grid-template-columns: repeat(auto-fill, minmax(px_unit(19), 1fr));
      padding: 0 $size_section_mobile_padding_side;
    }
  }
}
</style>
