<template>
  <ContentSection id="faq-section" title="よくある質問">
    <div class="faq-list">
      <div class="faq-row-item">
        <FaqRow
          question="支払い方法は何に対応していますか？"
          answer="支払いは以下のブランドのクレジットカードに対応しています
        JCB・MasterCard・VISA・AmericanExpress・Discover・Diners Club"
        />
      </div>
      <div class="faq-row-item">
        <FaqRow
          question="クイズ前にルール説明のスライドを入れられますか？"
          answer="現在対応しているのは「表紙」「問題」「ランキング」のスライドのみです
        お手数をおかけしますが 本サービスとは別でスライドを用意いただければと思います"
        />
      </div>
      <div class="faq-row-item">
        <FaqRow
          question="事前に二次元コードを配布して参加登録してもらうことはできますか？"
          answer="管理画面の「参加者受付を開始する」を事前にONにすることで当日より前でも参加登録を開始することができます"
        />
      </div>
    </div>
    <nuxt-link to="/media/SBhMGcXw7SeO9QXUFh6oi">
      <Button type="primary" color="dark-black">
        もっと見る
      </Button>
    </nuxt-link>
  </ContentSection>
</template>

<script>
import ContentSection from '../../general/ContentSection.vue'
import Button from '../../general/Button.vue'
import FaqRow from './FaqRow.vue'

export default {
  components: { ContentSection, Button, FaqRow },
  props: {}
}
</script>

<style scoped lang="scss">
#faq-section {
  background-color: $color_fill_white_100;

  .faq-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-top: 1px solid $color_fill_black_010;
    margin-bottom: px_unit(7);
    @include for_mobile {
      margin-bottom: px_unit(4);
    }
  }
  .faq-row-item {
    border-bottom: 1px solid $color_fill_black_010;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include for_mobile {
      padding: 0 px_unit(2);
    }
  }
}
</style>
