<template>
  <div>
    <main>
      <StickHeader
        :login-url="loginUrl"
        :signup-url="editorUrl"
        :scroll-y="scrollY"
        @onLogin="sendClickLoginEventToGA($ga)"
        @onSignup="sendClickEditorEventToGA($ga)"
      />
      <FirstviewSection
        :login-url="loginUrl"
        :signup-url="editorUrl"
        @onLogin="sendClickLoginEventToGA($ga)"
        @onSignup="sendClickEditorEventToGA($ga)"
      />
      <IntroductionSection />
      <MainFeatureSection />
      <DemoSection
        :demo-url="demoUrl"
        @onStartDemo="sendClickDemoEventToGA($ga)"
      />
      <VariousFeatureSection />
      <ConversionSection
        :signup-url="editorUrl"
        @onCheckPrice="sendClickCheckPriceEventToGA($ga)"
        @onSignup="sendClickEditorEventToGA($ga)"
      />
      <UseCaseSection />
      <VoicesSection />
      <PricesSection
        :signup-url="editorUrl"
        :demo-url="demoUrl"
        @onSignup="sendClickEditorEventToGA($ga)"
        @onStartDemo="sendClickDemoEventToGA($ga)"
      />
      <ColumnsSection />
      <FaqSection />
      <ConversionSection
        :signup-url="editorUrl"
        @onCheckPrice="sendClickCheckPriceEventToGA($ga)"
        @onSignup="sendClickEditorEventToGA($ga)"
      />
      <Footer />
    </main>
    <div :class="{ loaded: isLoaded, loading: true }">
      <spinner-with-label />
    </div>
  </div>
</template>

<script>
import StickHeader from '../components/sections/header/StickHeader.vue'
import FirstviewSection from '../components/sections/firstview/FirstviewSection.vue'
import IntroductionSection from '../components/sections/introduction/IntroductionSection.vue'
import MainFeatureSection from '../components/sections/main-features/MainFeatureSection.vue'
import DemoSection from '../components/sections/demo/DemoSection.vue'
import VariousFeatureSection from '../components/sections/various-feature/VariousFeatureSection.vue'
import ConversionSection from '../components/sections/conversion/ConversionSection.vue'
import UseCaseSection from '../components/sections/use-case/UseCaseSection.vue'
import VoicesSection from '../components/sections/voices/VoicesSection.vue'
import PricesSection from '../components/sections/prices/PricesSection.vue'
import ColumnsSection from '../components/sections/columns/ColumnsSection.vue'
import FaqSection from '../components/sections/faq/FaqSection.vue'
import Footer from '../components/sections/footer/Footer.vue'
import {
  buildAdminUrl,
  buildJoinUrl,
  sendClickEditorEventToGA,
  sendClickDemoEventToGA,
  sendClickLoginEventToGA,
  sendClickCheckPriceEventToGA
} from '~/helper'
import SpinnerWithLabel from '~/components/molcules/SpinnerWithLabel'
import config from '~/config'

export default {
  components: {
    SpinnerWithLabel,
    StickHeader,
    FirstviewSection,
    IntroductionSection,
    MainFeatureSection,
    DemoSection,
    VariousFeatureSection,
    ConversionSection,
    UseCaseSection,
    VoicesSection,
    PricesSection,
    ColumnsSection,
    FaqSection,
    Footer
  },
  head: {
    title: `${config.title}`,
    link: [{ rel: 'canonical', href: `${config.url}` }]
  },
  data: () => ({
    isLoaded: false,
    scrollY: 0
  }),
  computed: {
    loginUrl() {
      return buildAdminUrl('/login')
    },
    editorUrl() {
      return buildAdminUrl('/signup')
    },
    demoUrl() {
      return buildJoinUrl('/demo')
    }
  },
  mounted() {
    setTimeout(() => {
      this.isLoaded = true
    }, 1000)
    window.addEventListener('scroll', this.getScroll)
  },
  methods: {
    sendClickEditorEventToGA,
    sendClickDemoEventToGA,
    sendClickLoginEventToGA,
    sendClickCheckPriceEventToGA,
    getScroll() {
      this.scrollY = window.scrollY
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  overflow: hidden;
}

.loading {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease-out;
  opacity: 1;
  z-index: 100;
}
.loaded {
  visibility: hidden;
  opacity: 0;
}
</style>
