<template>
  <div class="feature-card">
    <img
      :src="require(`~/assets/images/feature_${image}.png`)"
      :alt="description"
    />
    <Typography
      class="feature-card-description"
      type="text"
      typelevel="1"
      color="black"
      colorscale="060"
      sptype="text"
      sptypelevel="3"
      >{{ description }}</Typography
    >
  </div>
</template>

<script>
import Typography from '../../general/Typography.vue'

export default {
  components: { Typography },
  props: {
    image: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.feature-card {
  padding: px_unit(4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // width: px_unit(31);
  height: px_unit(31);
  @include card_style();
  @include for_mobile {
    // width: px_unit(19.5);
    height: px_unit(19.5);
    height: auto;
    padding: px_unit(2);
    justify-content: center;
  }
  img {
    width: px_unit(22.5);
    height: px_unit(15);
    @include for_mobile {
      width: 100%;
      height: auto;
      margin-bottom: px_unit(0.5);
    }
  }
  &-description {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}
</style>
