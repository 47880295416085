<template>
  <section
    id="introduction-section"
    :style="
      'background-image:url(' +
        require(`~/assets/images/pattern_transparent_green.png`) +
        ')'
    "
  >
    <div class="content-wrapper">
      <Typography
        type="heading"
        typelevel="5"
        color="black"
        colorscale="060"
        sptype="button"
        sptypelevel="1"
      >
        結婚式2次会の余興やゲームの<MobileBreak />アイデアにお困りですか？
      </Typography>
      <h1>
        <Typography
          type="heading"
          typelevel="3"
          color="black"
          colorscale="100"
          sptype="heading"
          sptypelevel="4"
        >
          忙しい幹事のあなたを
        </Typography>

        <Typography
          type="heading"
          typelevel="3"
          color="black"
          colorscale="100"
          sptype="heading"
          sptypelevel="4"
          ><Logo class="heading-logo" color="black" /><MobileBreak />
          がサポートします
        </Typography>
      </h1>
    </div>
  </section>
</template>

<script>
import Logo from '../../general/Logo.vue'
import Typography from '../../general/Typography.vue'
import MobileBreak from '../../general/MobileBreak.vue'

export default {
  components: { Typography, Logo, MobileBreak },
  props: {}
}
</script>

<style scoped lang="scss">
#introduction-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: $size_inserted_section_desktop_height;
  background-size: 580px;
  background-position: center;

  @include for_mobile {
    height: auto;
    background-size: 400px;
    padding-top: $size_section_mobile_padding_top;
    padding-bottom: $size_section_mobile_padding_bottom;
  }

  .content-wrapper {
    width: $size_breakpoint_desktop;
    @include for_tablet {
      width: 100vw;
      padding: 0 $size_section_mobile_padding_side;
    }
    @include for_mobile {
      width: 100vw;
      padding: 0 $size_section_mobile_padding_side;
    }
  }
  h1 {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    gap: px_unit(1);
    margin-top: px_unit(2);

    @include for_tablet {
      flex-direction: column;
      align-items: center;
      gap: px_unit(2);
    }
    @include for_mobile {
      flex-direction: column;
      align-items: center;
      gap: px_unit(2);
    }

    .heading-logo {
      height: px_unit(4);
      transform: translateY(2px);
      @include for_tablet {
        transform: translateY(2px);
      }
      @include for_mobile {
        height: px_unit(3.5);
        transform: translateY(-3px);
      }
    }
  }
}
</style>
