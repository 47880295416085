<template>
  <nuxt-link :class="['article-card', size]" :to="to" target="_blank">
    <img :src="imageUrl" :alt="title" class="article-card-image" />
    <div class="article-card-text">
      <Typography
        class="article-card-text-title"
        type="heading"
        typelevel="5"
        color="black"
        colorscale="100"
        sptype="button"
        sptypelevel="1"
      >
        {{ title }}
      </Typography>
      <Typography
        class="article-card-text-tag"
        type="text"
        typelevel="1"
        color="green"
        colorscale="100"
        sptype="text"
        sptypelevel="2"
      >
        {{ `#${tag}` }}
      </Typography>
    </div>
  </nuxt-link>
</template>

<script>
import Typography from '../../general/Typography.vue'

export default {
  components: { Typography },
  props: {
    title: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      required: true
    },
    imageUrl: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.article-card {
  @include card_style;
  text-align: left;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  &:hover,
  &:focus {
    transform: translateY(-3px);
    opacity: 0.7;
    @include box_shadow_2($color_fill_black_100);
  }
  &:active {
    transform: translateY(0px);
    box-shadow: none;
  }
  &.large {
    width: 512px;
    height: 416px;
    display: flex;
    flex-direction: column;
    @include for_tablet {
      width: 100%;
      height: auto;
    }
    @include for_mobile {
      width: 100%;
      height: auto;
    }
    .article-card-image {
      width: 512px;
      height: 280px;
      object-fit: cover;
      @include for_tablet {
        width: 100%;
        height: 100px;
      }
      @include for_mobile {
        width: 100%;
        height: 100px;
      }
    }
    .article-card-text-title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  &.small {
    width: 512px;
    height: 200px;
    display: flex;
    flex-direction: row;
    @include for_tablet {
      width: 100%;
      height: auto;
      flex-direction: column;
    }
    @include for_mobile {
      width: 100%;
      height: auto;
      flex-direction: column;
    }

    .article-card-image {
      width: 248px;
      height: 200px;
      object-fit: cover;
      @include for_tablet {
        width: 100%;
        height: 100px;
      }
      @include for_mobile {
        width: 100%;
        height: 100px;
      }
    }
    .article-card-text-title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      @include for_mobile {
        -webkit-line-clamp: 2;
        min-height: 44.8px;
      }
    }
  }
  &-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: px_unit(3) px_unit(3) px_unit(3.5);
    &-tag {
      text-align: right;
    }
    @include for_tablet {
      justify-content: center;
      gap: px_unit(1);
      padding: px_unit(2.5) px_unit(2.5) px_unit(3);
    }
    @include for_mobile {
      justify-content: center;
      gap: px_unit(1);
      padding: px_unit(2.5) px_unit(2.5) px_unit(3);
    }
  }
}
</style>
