<template>
  <ContentSection
    id="prices-section"
    title="ご利用料金"
    class="content-section"
  >
    <div class="price-card-list">
      <PriceCard
        name="フリープラン"
        :price-non-taxed="0"
        :price-taxed="0"
        color="black"
        :guests="10"
      />
      <PriceCard
        name="お手軽プラン"
        :price-non-taxed="7321"
        :price-taxed="8053"
        color="yellow"
        :guests="50"
        :price-per-guest="300"
        :price-per-guest-assumption="30"
      />
      <PriceCard
        name="安心プラン"
        :price-non-taxed="13807"
        :price-taxed="15187"
        color="green"
        :guests="300"
        :price-per-guest="200"
        :price-per-guest-assumption="80"
      />
    </div>
    <a :href="signupUrl" @click="handleSignup">
      <Button type="primary" color="dark-red" class="price-button"
        >まずは無料でお試し</Button
      >
    </a>
    <a :href="demoUrl" @click="handleStartDemo">
      <TextLink type="primary" color="green">サンプルを確認する</TextLink>
    </a>
  </ContentSection>
</template>

<script>
import ContentSection from '../../general/ContentSection.vue'
import Button from '../../general/Button.vue'
import TextLink from '../../general/TextLink.vue'
import PriceCard from './PriceCard.vue'

export default {
  components: { ContentSection, Button, PriceCard, TextLink },
  props: {
    signupUrl: {
      type: String,
      required: true
    },
    demoUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    handleSignup() {
      this.$emit('onSignup')
    },
    handleStartDemo() {
      this.$emit('onStartDemo')
    }
  }
}
</script>

<style scoped lang="scss">
#prices-section {
  background-color: $color_fill_white_100;

  .price-card-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: px_unit(2);
    margin-bottom: px_unit(7);
    @include for_tablet {
      flex-direction: column;
      margin-bottom: px_unit(4);
      width: 100vw;
      padding: 0 $size_section_mobile_padding_side;
    }
    @include for_mobile {
      flex-direction: column;
      gap: px_unit(1.5);
      margin-bottom: px_unit(4);
      width: 100vw;
      padding: 0 $size_section_mobile_padding_side;
    }
  }
  .price-button {
    margin-bottom: px_unit(4);
    @include for_mobile {
      margin-bottom: px_unit(3);
    }
  }
}
</style>
