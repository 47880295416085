<template>
  <section id="firstview-section">
    <div class="firstview-header">
      <Logo class="header-logo" color="black" />
      <div class="header-actions header-actions-desktop">
        <nuxt-link to="/media">
          <TextLink color="black">コラム</TextLink>
        </nuxt-link>
        <nuxt-link to="/media/SBhMGcXw7SeO9QXUFh6oi">
          <TextLink color="black">よくある質問</TextLink>
        </nuxt-link>
        <a :href="loginUrl" @click="handleLogin">
          <Button
            class="header-login-button"
            type="secondary"
            color="light-green"
            >ログイン</Button
          >
        </a>
      </div>
      <a :href="loginUrl" class="header-actions-mobile" @click="handleLogin">
        <TextLink color="green">ログイン</TextLink>
      </a>
    </div>
    <div class="content-wrapper">
      <div class="firstview-cta">
        <h2>
          <Typography
            type="heading"
            typelevel="1"
            color="black"
            colorscale="100"
            class="firstview-cta-tagline"
            sptype="heading"
            sptypelevel="2"
            >新郎新婦を<MobileBreak />支える<DesktopBreak />縁の下の<MobileBreak />力持ちに</Typography
          >
        </h2>
        <h2>
          <Typography
            type="subheading"
            typelevel="1"
            color="black"
            colorscale="060"
            class="firstview-cta-tagline"
            sptype="text"
            sptypelevel="1"
            >{{
              '結婚式の2次会が盛り上がるゲーム\n新郎新婦クイズを今すぐ準備しよう'
            }}</Typography
          >
        </h2>
        <a :href="signupUrl" target="_self" @click="handleSignup">
          <Button
            class="firstview-cta-button desktop"
            type="primary"
            color="dark-red"
            >まずは無料でお試し</Button
          >
        </a>
      </div>
      <div class="firstview-screens">
        <img
          class="screen-card desktop"
          :src="require(`~/assets/images/desktop_01.png`)"
        />
        <img
          class="screen-card mobile"
          :src="require(`~/assets/images/mobile_02.png`)"
        />
      </div>
      <a :href="signupUrl" target="_self" @click="handleSignup">
        <Button
          class="firstview-cta-button mobile"
          type="primary"
          color="dark-red"
          >まずは無料でお試し</Button
        >
      </a>
    </div>
  </section>
</template>

<script>
import Logo from '../../general/Logo.vue'
import Typography from '../../general/Typography.vue'
import TextLink from '../../general/TextLink.vue'
import Button from '../../general/Button.vue'
import MobileBreak from '../../general/MobileBreak.vue'
import DesktopBreak from '../../general/DesktopBreak.vue'

export default {
  components: { Typography, Logo, TextLink, Button, MobileBreak, DesktopBreak },
  props: {
    loginUrl: {
      type: String,
      required: true
    },
    signupUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    handleSignup() {
      this.$emit('onSignup')
    },
    handleLogin() {
      this.$emit('onLogin')
    }
  }
}
</script>

<style scoped lang="scss">
#firstview-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: $size_firstview_desktop_height;
  overflow: hidden;
  background-image: url('~@/assets/images/firstview_background.png');
  background-size: cover;
  background-position: center 0;
  background-repeat: no-repeat;
  @include for_mobile {
    height: auto;
    background-image: url('~@/assets/images/firstview_background_mobile.png');
  }

  .firstview-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: $size_breakpoint_desktop_outer;
    width: 100vw;
    height: $size_header_desktop_height;
    position: absolute;
    top: 0px;
    z-index: 2;
    @include for_tablet {
      width: 100vw;
      padding: 0 $size_section_mobile_padding_side;
    }
    @include for_mobile {
      width: 100vw;
      padding: 0 $size_section_mobile_padding_side;
      height: $size_header_mobile_height;
      position: relative;
    }
  }
  .header {
    &-logo {
      height: px_unit(3);
      @include for_mobile {
        height: px_unit(2.5);
      }
    }
    &-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: px_unit(3);
      &-desktop {
        @include for_mobile {
          display: none;
        }
      }
      &-mobile {
        display: none;
        @include for_mobile {
          display: block;
        }
      }
    }
    &-login-button {
      width: $size_smallbutton_desktop_width;
      height: $size_smallbutton_desktop_height;
    }
  }
  .content-wrapper {
    width: $size_breakpoint_desktop;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    align-items: flex-start;

    @include for_tablet {
      width: 100vw;
      padding: 0 $size_section_mobile_padding_side;
    }

    @include for_mobile {
      align-items: center;
      height: auto;
      width: 100vw;
      // padding: $size_section_mobile_padding_top 0
      //   $size_section_mobile_padding_bottom;
      padding: px_unit(4) 0 $size_section_mobile_padding_bottom;
    }
  }

  .firstview-cta {
    z-index: 1;
    @include for_mobile {
      width: 100vw;
      padding: 0 $size_section_mobile_padding_side;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    h2 {
      margin-bottom: px_unit(4);
      display: flex;
      flex-direction: column;
      gap: px_unit(2);
      @include for_mobile {
        width: 100%;
        margin-bottom: px_unit(2);
      }
    }
    &-tagline {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    &-button {
      margin-top: px_unit(6);
      &.desktop {
        display: flex;
        @include for_mobile {
          display: none;
        }
      }
      &.mobile {
        display: none;
        @include for_mobile {
          display: flex;
        }
      }
    }
  }
  .firstview-screens {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    z-index: 0;
    @include for_mobile {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: px_unit(2);
      height: auto;
      bottom: 0px;
      right: none;
      width: 100%;
      position: relative;
    }
    .screen-card {
      @include card_style();
      &.desktop {
        width: 500px;
        height: 380px;
        position: absolute;
        right: -250px;
        top: $size_header_desktop_height;
        @include for_mobile {
          height: 190px;
          width: 250px;
          position: relative;
          top: 0px;
          right: 0px;
        }
      }
      &.mobile {
        width: 260px;
        height: 560px;
        position: absolute;
        bottom: -60px;
        right: 80px;
        @include for_mobile {
          height: 280px;
          width: 130px;
          position: relative;
          top: 0px;
          right: 0px;
        }
      }
    }
  }
}
</style>
