<template>
  <section id="main-feature-section">
    <div class="content-wrapper">
      <div class="feature01 feature">
        <div class="content-title">
          <h1>
            <Typography
              type="heading"
              typelevel="3"
              color="black"
              colorscale="100"
              sptype="heading"
              sptypelevel="4"
            >
              本番まで<MobileBreak />時間がなくても大丈夫
            </Typography>
          </h1>
          <Typography
            type="subheading"
            typelevel="3"
            color="black"
            colorscale="060"
            sptype="text"
            sptypelevel="1"
          >
            1からスライドを準備する<MobileBreak />必要はありません<br />
            クイズと答えを入力するだけで<MobileBreak />スライドが完成します
          </Typography>
        </div>
        <div class="screen-card-list">
          <img
            class="screen-card desktop"
            :src="require(`~/assets/images/desktop_01.png`)"
            alt="第1問 ふたりの初デートの場所は？"
          />
          <img
            class="screen-card desktop"
            :src="require(`~/assets/images/desktop_02.png`)"
            alt="第2問 付き合ったきっかけはユウからの告白である"
          />
          <img
            class="screen-card desktop"
            :src="require(`~/assets/images/desktop_03.png`)"
            alt="第3問 プロポーズの言葉は？"
          />
        </div>
      </div>
      <div class="feature02 feature">
        <div class="content-title">
          <h1>
            <Typography
              type="heading"
              typelevel="3"
              color="black"
              colorscale="100"
              sptype="heading"
              sptypelevel="4"
            >
              ２次会が<MobileBreak />オンラインでも安心
            </Typography>
          </h1>
          <Typography
            type="subheading"
            typelevel="3"
            color="black"
            colorscale="060"
            sptype="text"
            sptypelevel="1"
          >
            クイズの内容は参加者の<MobileBreak />スマートフォンに表示されます<br />
            アプリをダウンロードして<MobileBreak />もらう必要もありません
          </Typography>
        </div>
        <div class="screen-card-list">
          <img
            class="screen-card mobile"
            :src="require(`~/assets/images/mobile_01.png`)"
            alt="リョウタとユウのウェディングパーティ"
          />
          <img
            class="screen-card mobile"
            :src="require(`~/assets/images/mobile_02.png`)"
            alt="第1問 ふたりの初デートの場所は？"
          />
          <img
            class="screen-card mobile"
            :src="require(`~/assets/images/mobile_03.png`)"
            alt="第2問 付き合ったきっかけはユウからの告白である"
          />
          <img
            class="screen-card mobile"
            :src="require(`~/assets/images/mobile_04.png`)"
            alt="第3問 プロポーズの言葉は？"
          />
          <img
            class="screen-card mobile"
            :src="require(`~/assets/images/mobile_05.png`)"
            alt="ランキング 1位 太郎"
          />
        </div>
      </div>
      <div class="feature03 feature">
        <div class="content-title">
          <h1>
            <Typography
              type="heading"
              typelevel="3"
              color="black"
              colorscale="100"
              sptype="heading"
              sptypelevel="4"
            >
              クイズの回答を集計する<MobileBreak />必要はありません
            </Typography>
          </h1>
          <Typography
            type="subheading"
            typelevel="3"
            color="black"
            colorscale="060"
            sptype="text"
            sptypelevel="1"
          >
            クイズ終了後に<MobileBreak />ランキングを自動で作成します<br />
            参加者のスマートフォンから<MobileBreak />結果がすぐにわかります
          </Typography>
        </div>
        <div class="screen-card-list">
          <img
            class="screen-card desktop"
            :src="require(`~/assets/images/desktop_05.png`)"
            alt="ランキング 1位 太郎"
          />
          <img
            class="screen-card mobile"
            :src="require(`~/assets/images/mobile_08.png`)"
            alt="あなたの順位 1位"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Typography from '../../general/Typography.vue'
import MobileBreak from '../../general/MobileBreak.vue'

export default {
  components: { Typography, MobileBreak },
  props: {}
}
</script>

<style scoped lang="scss">
#main-feature-section {
  padding-top: $size_section_desktop_padding_top;
  padding-bottom: $size_section_desktop_padding_bottom;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: $color_fill_green_010;
  @include for_mobile {
    padding-top: $size_section_mobile_padding_top;
    padding-bottom: $size_section_mobile_padding_bottom;
    text-align: left;
  }

  .content-wrapper {
    width: 100vw;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: px_unit(12);
    @include for_mobile {
      gap: px_unit(8);
      text-align: left;
    }
  }
  .feature {
    display: flex;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .content-title {
    width: $size_breakpoint_desktop;
    margin-bottom: px_unit(5);
    h1 {
      margin-bottom: px_unit(3);
    }

    @include for_mobile {
      width: 100vw;
      padding: 0 $size_section_mobile_padding_side;
    }
  }
  .screen-card-list {
    display: flex;
    flex-direction: row;
    gap: px_unit(4);
    @include for_mobile {
      gap: px_unit(1.5);
    }
    .screen-card {
      @include card_style();
      &.desktop {
        width: 500px;
        height: 380px;
        @include for_mobile {
          width: 250px;
          height: 190px;
        }
      }
      &.mobile {
        width: 260px;
        height: 560px;
        @include for_mobile {
          height: 280.0000305175781px;
          width: 130px;
        }
      }
    }
  }
  .feature03 {
    .screen-card-list {
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
