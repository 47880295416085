<template>
  <ContentSection
    id="columns-section"
    title="コラム"
    description="結婚式2次会の準備に役立つ 情報をまとめています"
  >
    <div class="columns-article-list">
      <ArticleCard
        title="Ennoshitaの使い方 - 結婚式の二次会を新郎新婦クイズで盛り上げよう"
        tag="チュートリアル"
        image-url="http://images.ctfassets.net/79j1v2v8az3c/5jHBCLRZgYSX1SJ9GeEUBN/1bb0e1e59065e57f5dbc95b4552e97fc/image3.png"
        size="small"
        to="/media/2JjtrqhLcGc0Chju5uHxae"
      />
      <ArticleCard
        title="ZIP!でEnnoshitaが紹介されました！"
        tag="ニュース"
        image-url="https://images.ctfassets.net/79j1v2v8az3c/4JGMD89NhDIc1J86EZAzFn/5f8f87b9902959d29707994f8a96f11f/PXL_20230613_232502015.TS_exported_470837_1686699321979-2.jpg"
        size="small"
        to="/media/1cxLK3jt8CWBmwxDfpb2sr"
      />
      <ArticleCard
        title="【アプリ不要】新郎新婦クイズがオンライン結婚式の余興にぴったりな３つの理由"
        tag="オンライン"
        image-url="http://images.ctfassets.net/79j1v2v8az3c/npD599CF2Ha6OeBIuvzwt/beebe2c81091805e6a4fdf6d251ed75e/_______________.png"
        size="small"
        to="/media/20teAqq3kiEEK1zFi3ML3B"
      />
      <ArticleCard
        title="結婚式二次会でクイズ大会をするときはパワーポイントよりもEnnoshitaでお手軽に作ろう！"
        tag="おすすめ"
        image-url="http://images.ctfassets.net/79j1v2v8az3c/7b5JCR3f5kSqpfLCZo4aQK/8425a71599a7a38e7c20e13649c821b5/image32.gif"
        size="small"
        to="/media/7kGrsQTMYntRI92I4Ddke4"
      />
    </div>
    <nuxt-link to="/media">
      <Button type="primary" color="dark-black" class="columns-button"
        >もっと見る</Button
      >
    </nuxt-link>
  </ContentSection>
</template>

<script>
import ContentSection from '../../general/ContentSection.vue'
import Button from '../../general/Button.vue'
import ArticleCard from './ArticleCard.vue'

export default {
  components: { ContentSection, Button, ArticleCard },
  props: {}
}
</script>

<style scoped lang="scss">
#columns-section {
  background-color: $color_fill_black_005;
  .columns-article {
    &-list {
      display: grid;
      width: $size_breakpoint_desktop;
      height: 416px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: px_unit(2);
      margin-bottom: px_unit(7);
      @include for_tablet {
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: column;
        margin-bottom: px_unit(4);
      }
      @include for_mobile {
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: column;
        margin-bottom: px_unit(4);
      }
    }
    &-card-1 {
      grid-row: 1 / 3;
      grid-column: 1 / 2;
    }
    &-card-2 {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
    &-card-3 {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }
}
</style>
